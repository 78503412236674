import React from 'react'

const NotFoundPage = () => (
  <>
    <h1>Error</h1>
    <p>Please contact support@glpg.net</p>
  </>
)

export default NotFoundPage
